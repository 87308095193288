























import {
  Prop, Ref, Component, Vue,
} from 'vue-property-decorator';

import { Scores } from '../Storage';
import { openModal, closeModal } from '../ModalHelpers';
import { emptyQuestion, Question, Category } from '../question';
import CategoryQuestionList from './CategoryQuestionList.vue';
import QuestionModal from './QuestionModal.vue';

@Component({
  components: {
    CategoryQuestionList,
    QuestionModal,
  },
})
export default class QuestionPanel extends Vue {
  @Ref() private questionModal!: QuestionModal;
  @Prop() private readonly categories!: Array<Category>;
  @Prop() private readonly scores!: Scores;

  private questionClicked = emptyQuestion('');
  private clickIndex = -1;
  private showAnswer = false;

  private onClickQuestion(i: number, q: Question) {
    if (q.seenAnswer) {
      return;
    }

    this.questionClicked = q;
    this.clickIndex = i;

    this.questionModal.init(Object.keys(this.scores), q);
    openModal(this.questionModal);
  }

  private onCancelFocus() {
    closeModal(this.questionModal);
    this.questionClicked = emptyQuestion('');
    this.clickIndex = -1;
  }

  private onShowAnswer() {
    this.$emit('view-answer', this.questionClicked);
  }

  private onAnswerQuestion(team: string) {
    this.$emit('add-score', team, this.questionClicked);
    this.onCancelFocus();
  }
}
