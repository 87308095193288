

































import { Ref, Component, Vue } from 'vue-property-decorator';
import M from 'materialize-css';

import {
  getCategories, getTeams, setTeams, Scores, setCategories,
} from './Storage';
import { Question, Category } from './question';
import Scoreboard from './components/Scoreboard.vue';
import QuestionPanel from './components/QuestionPanel.vue';
import ScoringToast from './components/ScoringToast.vue';

@Component({
  components: {
    Scoreboard,
    QuestionPanel,
    ScoringToast,
  },
})
export default class App extends Vue {
  @Ref() private scoreboard!: Scoreboard;
  @Ref() private scoringToast!: ScoringToast;

  private scores: Scores = getTeams();
  private categories: Array<Category> = getCategories();

  private onPulloutScoreboard() {
    const instance = M.Sidenav.init(this.scoreboard.$el, {});
    instance.open();
  }

  private onAddScore(team: string, q: Question) {
    this.scoringToast.init(team, this.scores[team], Number(q.points));
    this.$set(this.scores, team, this.scores[team] + Number(q.points));
    q.answeredBy = team;

    this.scoringToast.display();
    setTeams(this.scores);
    setCategories(this.categories);
  }

  private onAddTeam(team: string) {
    if (Object.prototype.hasOwnProperty.call(this.scores, team)) {
      return;
    }
    this.$set(this.scores, team, 0);
    setTeams(this.scores);
  }

  private onRemoveTeam(team: string) {
    this.$delete(this.scores, team);
    setTeams(this.scores);
  }

  private onViewAnswer(q: Question) {
    q.seenAnswer = true;
    setCategories(this.categories);
  }

  private pulltabClass() {
    return {
      pulse: this.categories.length > 0
      && Object.keys(this.scores).length === 0,
    };
  }
}
