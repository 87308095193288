











import { Prop, Component, Vue } from 'vue-property-decorator';
import { Question, Category } from '../question';

@Component
export default class CategoryQuestionList extends Vue {
  @Prop() private category!: Category;

  // eslint-disable-next-line
  private questionClass(q: Question): any {
    return {
      'theme-main': !q.seenAnswer,
      'theme-disabled': q.seenAnswer,
      unanswered: !q.seenAnswer,
      answer: q.seenAnswer,
    };
  }
}
