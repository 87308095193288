








import { Prop, Component, Vue } from 'vue-property-decorator';

@Component
export default class TeamToast extends Vue {
  @Prop() private rank!: number;
  @Prop() private team!: string;
  @Prop() private score!: number;
}
