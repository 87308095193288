






























import { Component, Vue } from 'vue-property-decorator';
import showdown from 'showdown';
import { Question, emptyQuestion } from '../question';

const converterConfig = {
  tables: true,
};

@Component
export default class QuestionModal extends Vue {
  private readonly converter = new showdown.Converter(converterConfig);
  private showAnswer = false;
  private teams: Array<string> = [];
  private question: Question = emptyQuestion('');

  private onShowAnswer() {
    this.showAnswer = true;
    this.$emit('show-answer');
  }

  public init(teams: Array<string>, question: Question) {
    this.showAnswer = false;
    this.teams = teams;
    this.question = question;
  }
}
