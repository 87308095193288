

















import { Component, Vue } from 'vue-property-decorator';

@Component
export default class AddTeam extends Vue {
  private addTeamPressed = false;
  private teamName = '';

  private onPressAddTeam() {
    this.addTeamPressed = true;

    setTimeout(() => {
      const f = document.getElementById('teamName');
      if (f) {
        f.focus();
      }
    }, 100);
  }

  private onAddTeam() {
    if (this.teamName === '') {
      return;
    }

    this.$emit('add-team', this.teamName);
    this.teamName = '';
  }
}
